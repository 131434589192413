import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './DropdownLinks.module.scss';
import Icon from '../Icon';

const DropdownLinks = ({
    items,
    modifier,
    languageEquivalent,
    isSmall,
    icon = '',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const activeItem = items.find((l) => l.active);
    const inActiveItems = items.filter((l) => !l.active);

    if (!activeItem) {
        return null;
    }

    const classes = classNames(styles['DropdownLinks'], {
        [styles['DropdownLinks--isSmall']]: isSmall,
    });

    const dropdownId = modifier
        ? `dropdown-items-${modifier}`
        : 'dropdown-items';

    return (
        <div className={classes}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                aria-controls={dropdownId}
                className={classNames(styles['DropdownLinks__Button'], {
                    [styles['DropdownLinks__Button--Open']]: isOpen,
                })}>
                {icon && (
                    <Icon
                        size={isSmall && 'small'}
                        type={icon}
                        modifiers={[styles['DropdownLinks__WebIcon']]}
                    />
                )}
                <span className={styles['DropdownLinks__Title']}>
                    {activeItem.title}
                </span>
                <Icon
                    size={isSmall && 'xs'}
                    type="chevron"
                    modifiers={[
                        styles['DropdownLinks__Icon'],
                        isOpen && styles['DropdownLinks__Icon--Open'],
                    ]}
                />
            </button>
            <div
                id={dropdownId}
                aria-hidden={!isOpen}
                className={classNames(styles['DropdownLinks__Menu'], {
                    [styles['DropdownLinks__Menu--Open']]: isOpen,
                })}>
                {inActiveItems.map((item, i) => {
                    const itemHref = languageEquivalent
                        ? languageEquivalent
                        : item.href;
                    return (
                        <a
                            key={i}
                            className={styles['DropdownLinks__MenuItem']}
                            lang={item.langCode}
                            href={itemHref}>
                            {item.title}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

DropdownLinks.propTypes = {
    items: PropTypes.array,
};

DropdownLinks.defaultProps = {
    items: [],
};

export default DropdownLinks;
